import { Col, Button } from "react-bootstrap";

export const ProjectCard = ({
  title,
  description,
  imgUrl,
  demoUrl,
  repoUrl,
}) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <div className="button-container">
            <Button
              href={demoUrl}
              target="_blank"
              rel="noopener noreferrer"
              variant="primary"
              className="mt-2"
            >
              Live Demo
            </Button>
            <Button
              href={repoUrl}
              target="_blank"
              rel="noopener noreferrer"
              variant="secondary"
              className="mt-2 ml-2"
            >
              GitHub Repo
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
};
