import { Container, Row, Col } from "react-bootstrap";

import logo from "../assets/nav-logo.png";
import navIcon1 from "../assets/linkedIn-icon.svg";
import navIcon2 from "../assets/github-icon.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <Link
                to="https://www.linkedin.com/in/youssef-fakir-777553292/"
                target="_blank"
              >
                <img src={navIcon1} alt="Icon" />
              </Link>
              <Link to="https://github.com/Youssef-f" target="_blank">
                <img src={navIcon2} alt="Icon" />
              </Link>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
